import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { AuthType } from 'types/Auth.type';
import { LocalStorage } from 'utils/localStorage';
import moment from 'moment-timezone'
import { utcToTimeZone } from "utils/time";
//ts-
const STORE_KEY = "wayo-auth-store";
type Store = {
    auth: AuthType | {
        [key: string | number]: any
    };
    isLogin: () => boolean;
    isTokenExpire: () => boolean;
    setState: (key: string, value: any) => void;
    resetState: () => void;
};
let initialState = {
    auth: {}
};
// @ts-ignore
initialState = LocalStorage.initStore(STORE_KEY, initialState)
const useAuthStore = create<Store>()(
    devtools(
        persist(
            (set, get) => ({
                ...initialState,
                isLogin: () => {
                    return Object.keys(get().auth).length > 0;
                },
                isTokenExpire: () => {
                    if (get().isLogin()) {
                        const now = utcToTimeZone({ timezone: "GMT", isFormat: false })
                        const expire = moment(get().auth.expire.date).format('LLLL');
                        // @ts-ignore
                        return now.isAfter(expire);
                    }
                    return true;
                },
                setState: (key, value) => {
                    set((s) => ({ [key]: value }));
                },

                resetState: () => {
                    set((s) => initialState);
                    LocalStorage.remove(STORE_KEY);
                },
            }),
            {
                name: STORE_KEY,
                storage: createJSONStorage(() => localStorage),
                version: 1,
            }
        )
    )
);

export default useAuthStore;
