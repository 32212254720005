import moment from 'moment-timezone';

export const dateObjToString = ({ dateObj, format = 'DD/MM/Y' }) => {
    return moment(dateObj).format(format);
};

export const utcToTimeZone = ({
    utc = moment.utc(),
    timezone = moment.tz.guess(),
    outFormat = 'DD/MM/Y',
    keepLocalTime = false,
    isFormat = true,
    toDate = false,
}) => {
    if (!utc) return utc;
    const date = moment.utc(utc).tz(timezone, keepLocalTime);
    if (toDate) {
        return date.toDate();
    }

    if (isFormat) {
        return date.format(outFormat);
    }
    return date;
};

export const calculateStayDays = ({
    startDate = moment(),
    endDate = moment()
}) => {
    const startStayDate = moment(startDate);
    const endStayDate = moment(endDate);
    const stayDays = endStayDate.diff(startStayDate, 'days');

    return stayDays - 1;
}

