import { modals } from '@mantine/modals';

export const Modal = {
    open: (modalChildren, props = {}) => {
        return (
            modalChildren &&
            modals.open({
                zIndex: 100,
                centered: true,
                children: <>{modalChildren}</>,
                ...props,
            })
        );
    },

    closeAll: () => {
        modals.closeAll();
    },
};
