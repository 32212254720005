export const LocalStorage = {
    set: (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    },
    get: (key) => {
        return JSON.parse(localStorage.getItem(key));
    },
    remove: (keys) => {
        if (Array.isArray(keys)) {
            keys.forEach((n) => {
                localStorage.removeItem(n);
            });
        }
        if (typeof keys == "string") {
            localStorage.removeItem(keys);
        }

    },
    clear: () => {
        localStorage.clear();
    },
    initStore: (key = "", state = {}) => {
        Object.keys(state).forEach(s => {
            state[s] = LocalStorage.get(key)?.state[s] || state[s]
        })
        return state;
    }
};
