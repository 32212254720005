//app config

const APP_MODE_TYPES = ["localhost", "dev", "s8", "staging", "live"]
const APP_MODE = "localhost";
// const APP_MODE = "dev";
// const APP_MODE = "staging";
// const APP_MODE = "live";

if (process.env.NODE_ENV !== "production") {
    switch (APP_MODE) {
        case 'localhost':
        case 'dev':
        case 's8':
            window.bookmy = {
                server_url: "http://bookmy.project8.inscribecms.com",
                stripe_key: "pk_test_51H2PthGIwQtmqndeLE8Z183ZgsI5IoOaInDH8SjmdbBKNNL0ilz9qg1OaHUbWPB48OjTkTqvw79yBvhYLqpAV4lW00Vl1CpDMw"
            }
            break;
        case 'staging':
            window.bookmy = {
                server_url: "https://demo.bookmy.com.au",
                stripe_key: "pk_test_51H2PthGIwQtmqndeLE8Z183ZgsI5IoOaInDH8SjmdbBKNNL0ilz9qg1OaHUbWPB48OjTkTqvw79yBvhYLqpAV4lW00Vl1CpDMw"
            }
            break;
        case 'live':
            window.bookmy = {
                server_url: "https://app.bookmy.com.au",
                stripe_key: "pk_live_51H2PthGIwQtmqnde4TC3mrlJFWjR4TzHSdsT2mAT5Co0ir7NOaJg5tBpjfOsbB4hq3hv8LgiytV0RUquXVwDG9wo00iacwDq0p"
            }
            break;
    }
}
// define a new console
const newConsole = (function (console) {
    return {
        log: function (text) {
            // console.log(text);
        },
        info: function (text) {
            // console.log(text);
        },
        warn: function (text) {
            // console.log(text);
        },
        error: function (text) {
            // console.log(text);

        }
    };
}(window.console));

if (process.env.NODE_ENV === "production") {
    window.console = newConsole;
}