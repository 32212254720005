

export const SERVER_URL = window.bookmy.server_url;
export const BASE_API_URL = `${SERVER_URL}/whatson_web/apis`;

export const DEFAULT_USER_META = {
    ap: 'besc',
    os: 2,
    av: '1.0.0',
    u: 0,
    d: 0,
    i: 'web',
};

export const STRIPE_SCRIPT_URL = 'https://js.stripe.com/v3/';
