// @ts-nocheck
import React, { lazy, Suspense, useRef, useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HelmetProvider } from 'react-helmet-async';
import { ReactQueryDevtools } from 'react-query/devtools';
import { DEFAULT_USER_META } from 'utils/constants/core.js';
import { LocalStorage } from "utils/localStorage";

const Loading = lazy(() => import('atoms/Loadings/Loading'));
const LoginTokenExpiredModal = lazy(() => import('atoms/Modals/LoginTokenExpiredModal'));
const queryClient = new QueryClient();
window.queryClient = queryClient;

if (!LocalStorage.get("meta")) {
	LocalStorage.set("meta", DEFAULT_USER_META)
}


export default function AppWrapper() {

	return (
		<>
			<div
				style={{
					minHeight: '100vh',
					margin: '0 auto',
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					position: 'relative',
				}}
			>

				<Router>
					<Suspense fallback={<Loading isLoading />}>
						<QueryClientProvider client={queryClient}>
							<HelmetProvider>
								<Notifications position="top-center" zIndex={Number.MAX_SAFE_INTEGER} autoClose={5000} />
								<ModalsProvider>
									<ReactQueryDevtools />
									<App />
								</ModalsProvider>
								<Loading />
							</HelmetProvider>
						</QueryClientProvider>
					</Suspense>
				</Router>
			</div >
		</>
	);
}
