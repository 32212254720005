import React, { lazy, useContext, useRef, useEffect } from 'react';
import {
    useRoutes,
    Navigate,
    useNavigate,
    useLocation,
    useSearchParams,
    useParams,
} from 'react-router-dom';
import { LocalStorage } from 'utils/localStorage';
import useUserStore from 'stores/UserStore';
import useAuthStore from 'stores/AuthStore';

const Home = lazy(() => import('screens/Home/Home'));
const EventDetailsTicketPurchasing = lazy(() => import('screens/WhatsOnTicketPurchasingScreens/EventDetailsTicketPurchasing'));
const EventTicketsTicketsPurchasing = lazy(() => import('screens/WhatsOnTicketPurchasingScreens/EventTicketsTicketsPurchasing'));
const EventTicketsPurchasingContact = lazy(() => import('screens/WhatsOnTicketPurchasingScreens/EventTicketsPurchasingContact'));
const EventTicketsPurchasingGuestsInfo = lazy(() => import('screens/WhatsOnTicketPurchasingScreens/EventTicketsPurchasingGuestsInfo'));
const EventTicketsPurchasingPayment = lazy(() => import('screens/WhatsOnTicketPurchasingScreens/EventTicketsPurchasingPayment'));
const EventTicketsPurchasingPurchasedTickets = lazy(() => import('screens/WhatsOnTicketPurchasingScreens/EventTicketsPurchasingPurchasedTickets'));
const EventTicketSendInvoice = lazy(() => import('screens/WhatsOnTicketPurchasingScreens/EventTicketSendInvoice'));
const CustomerTickets = lazy(() => import('screens/Tickets/CustomerTickets'));
const LikedEvents = lazy(() => import('screens/Events/LikedEvents'));
const CustomerAccount = lazy(() => import('screens/Account/Account'));
const HotelHome = lazy(() => import('screens/HotelHome/HotelHome'));
const HotelSearchResult = lazy(() => import('screens/HotelSearchResult/HotelSearchResult'));
const HotelDetails = lazy(() => import('screens/HotelDetails/HotelDetails'));
const RestaurantHome = lazy(() => import('screens/RestaurantHome/RestaurantHome'));
const Account = lazy(() => import('screens/Account/Account'));
const Bookings = lazy(() => import('screens/Bookings/Bookings'));
const BookingPayment = lazy(() => import('screens/Bookings/BookingPayment'));
const Login = lazy(() => import('screens/Login/Login'));
const Signup = lazy(() => import('screens/Signup/Signup'));
const Error404 = lazy(() => import('screens/Error404/Error404'));


function Routes() {
    const navigate = useNavigate();
    const location = useLocation();
    const UserStore = useUserStore((s) => s);
    const AuthStore = useAuthStore();
    const rootRoutePath = location.pathname.split('/')[1];
    const userPermissions = UserStore.user?.role_details?.permissions || [];

    const isAccessibleRoute = () => {
        if (process.env.NODE_ENV === "production") {
            const currentPath = location.pathname;
            return !["/"].some(route => {
                const regex = new RegExp(`^${route}(\/\\d+)*$`);
                return regex.test(currentPath);
            });
        }
        return true
    };

    useEffect(() => {
        if (!AuthStore.isLogin() && location.pathname != "/signup") {
            // navigate('/login');
            // LocalStorage.clear();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AuthStore.isLogin()]);

    useEffect(() => {
        // if (AuthStore.isLogin()) {
        //     const init = async () => {
        //         if (!isAccessibleRoute()) {
        //             // navigate('/404');
        //             return;
        //         }
        //     };
        //     init();
        // }
        const isAccessible = isAccessibleRoute()
        if (!isAccessible) {
            // navigate('/404');
            navigate('/404', { replace: true });
            return;
        }


    }, [location.pathname, location, navigate]);


    const routes = useRoutes([

        { path: '/', element: <Home /> },
        // { path: '/hotels', element: <HotelHome /> },
        // { path: '/hotel-search-result', element: <HotelSearchResult /> },
        // { path: '/restaurants', element: <RestaurantHome /> },
        { path: '/events/:id/:sid', element: <EventDetailsTicketPurchasing /> },
        { path: '/whatson-ticket-purchasing-event-tickets/:id/:sid', element: <EventTicketsTicketsPurchasing /> },
        { path: '/whatson-ticket-event-tickets-guests-info/:id/:sid', element: <EventTicketsPurchasingGuestsInfo /> },
        { path: '/whatson-ticket-purchasing-event-contact/:id/:sid', element: <EventTicketsPurchasingContact /> },
        { path: '/whatson-ticket-purchasing-event-payment/:id/:sid', element: <EventTicketsPurchasingPayment /> },
        { path: '/whatson-ticket-purchasing-event/tickets/:transaction_id', element: <EventTicketsPurchasingPurchasedTickets /> },
        { path: '/whatson-ticket-purchasing-event/send-invoice', element: <EventTicketSendInvoice /> },
        { path: '/customer-tickets', element: <CustomerTickets /> },
        { path: '/customer-liked-events', element: <LikedEvents /> },
        { path: '/customer-account', element: <CustomerAccount /> },
        // { path: '/account', element: <Account /> },
        // { path: '/bookings', element: <Bookings /> },
        { path: '/login', element: <Login /> },
        // { path: "/hotel/:id", element: <HotelDetails /> },
        // { path: "/hotel/:id/payment", element: <BookingPayment /> },
        // { path: "/support", element: <Login /> },
        {
            path: '/signup',
            element: <Signup />
        },

        { path: '/404', element: <Error404 /> },
        { path: '*', element: <Navigate to="/404" replace={true} /> },
    ]);

    return <>
        {routes}

    </>;
}
export default Routes;
